// npm
import { Component } from 'react';

// project
import Icon from '../../assets/img/logo-mobile.png';

// default
export default class extends Component {
	componentDidMount = () => {
		document.querySelector(`link[rel="shortcut icon"]`).href = Icon;
	};

	render = () => {
		return null;
	};
}
