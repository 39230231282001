// npm
import { useDispatch } from 'react-redux';

// project
import { setPath } from './reducer';

// default
export default ({ pathname }) => {
	const dispatch = useDispatch();

	dispatch(setPath(pathname.match(/\/[^/]+/g)));

	return null;
};
