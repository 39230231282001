// npm
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import classnames from 'classnames';
import $ from 'jquery';
import { TweenMax, ScrollToPlugin } from 'gsap/all';

// app
import Lang from '../app/language';
import { toggleMenuMobileVisible, setMobile, setPopupHome } from '../app/reducer';

// components
import Selector from './lang-selector';

// plugins
let plugins = [];
plugins.push(ScrollToPlugin);

// styled
export const Menu = styled.div`
	flex: 0 0 auto;

	&:not(.mobile) {
		margin: auto;
		margin-right: 0;

		flex-flow: row;
		display: flex;
	}

	&.mobile {
		transition: transform 0.3s ease-in-out;
		transform: translate(16rem, 0);

		background: rgba(0, 0, 0, 0.95);
		overflow-y: scroll;
		padding: 2rem 1rem;
		overflow-x: auto;
		position: fixed;
		width: 15rem;
		bottom: 0;
		top: 6rem;
		right: 0;
	}

	&.mobile.visible {
		transform: translate(0, 0);
	}
`;

export const Link = styled.button`
	flex: 0 0 auto;

	transition: 0.3s ease-in-out;
	transition-property: color;

	color: rgba(255, 255, 255, 1);
	background: transparent;
	border-radius: 0.25rem;
	position: relative;
	font-weight: 700;
	padding: 0.5rem;
	display: block;
	margin: auto 0;

	&:not(.mobile) {
		margin-right: 1rem;
	}

	&.mobile {
		margin-bottom: 1rem;
		padding: 0.5rem 0;
		width: 100%;
	}

	&.active {
		color: rgba(0, 204, 153, 1);
	}
`;

export const Action = styled.button`
	flex: 0 0 auto;

	transition: 0.3s ease-in-out;
	transition-property: opacity;

	margin: auto 0;
	margin-left: 0.5rem;

	background: rgba(0, 161, 120, 1);
	color: rgba(255, 255, 255, 1);
	border-radius: 2rem;
	padding: 0 2rem;
	height: 2.5rem;

	&:hover {
		opacity: 0.8;
	}

	&.mobile {
		margin-left: 0;
		width: 100%;
	}
`;

export const Responsive = styled.div`
	flex: 0 0 auto;

	margin: auto;
	margin-right: 0;
`;

export const RButton = styled.button`
	justify-content: center;
	background: transparent;
	align-items: center;
	flex-flow: column;
	display: flex;
	height: 3rem;
	width: 3rem;
`;

export const ButtonLine = styled.span`
	flex: 0 0 auto;

	transition: 0.3s ease-in-out;
	transition-property: transform;

	background: rgba(255, 255, 255, 1);
	border-radius: 1rem;
	position: relative;
	height: 0.25rem;
	display: block;
	width: 2rem;

	&:not(:last-child) {
		margin-bottom: 0.375rem;
	}
`;

// gera menu
const GenerateMenu = ({ mobile = false }) => {
	const dispatch = useDispatch();
	const active = useSelector(s => s.menu.active);

	const links = {
		'0': { Component: () => <Lang id='menu.home' />, target: '#home' },
		'1': { Component: () => <Lang id='menu.solutions' />, target: '#solutions' },
		'2': { Component: () => <Lang id='menu.company' />, target: '#company' },
		'3': { Component: () => <Lang id='menu.howitworks' />, target: '#how-it-works' },
		'4': { Component: () => <Lang id='menu.contact' />, target: '#contact' },
	};

	const linkOnClick = id => {
		let page = $('#page');

		let top = page.scrollTop() + $(id).offset().top - $('#navbar').outerHeight();

		TweenMax.to(page, 0.5, {
			scrollTo: {
				y: top < 0 ? 0 : top,
			},
		});

		if (mobile) {
			dispatch(toggleMenuMobileVisible());
		}
	};

	return Object.values(links).map((link, i) => (
		<Link key={i} className={classnames({ mobile: mobile, active: active === link.target })} onClick={() => linkOnClick(link.target)}>
			<link.Component />
		</Link>
	));
};

// modal
const ActionModal = ({ mobile = false }) => {
	const dispatch = useDispatch();

	const openModal = e => {
		e.preventDefault();

		dispatch(setPopupHome(true));
	};

	return (
		<Action className={classnames({ mobile })} onClick={e => openModal(e)}>
			<Lang id='menu.panel' />
		</Action>
	);
};

// menu mobile
const SResponsive = () => {
	const dispatch = useDispatch();
	const visible = useSelector(s => s.menu.mobile.visible);

	const responsiveOnClick = () => {
		dispatch(toggleMenuMobileVisible());
	};

	return (
		<Responsive>
			<RButton className={classnames({ mobile: visible })} onClick={() => responsiveOnClick()}>
				<ButtonLine className='line-1' />
				<ButtonLine className='line-2' />
				<ButtonLine className='line-3' />
			</RButton>

			<Menu mobile={true} className={classnames(['mobile', { visible: visible }])}>
				<GenerateMenu mobile={true} />

				<Selector mobile={true} />

				<ActionModal mobile={true} />
			</Menu>
		</Responsive>
	);
};

// check mobile
const MobileCheck = ({ mobile }) => {
	const dispatch = useDispatch();

	const mobileChecker = () => {
		const check = window.outerWidth < 1024;

		if (mobile !== check) {
			dispatch(setMobile(check));
		}
	};

	let event = 'resize.mobileCheck';

	$(window).off(event);
	$(window).on(event, () => mobileChecker());

	mobileChecker();

	return null;
};

// default
export default () => {
	const mobile = useSelector(s => s.mobile);

	return (
		<>
			<MobileCheck mobile={mobile} />

			{mobile ? (
				<SResponsive />
			) : (
				<Menu>
					<GenerateMenu />

					<Selector />

					<ActionModal />
				</Menu>
			)}
		</>
	);
};
