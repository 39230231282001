// npm
import React from 'react';
import styled from 'styled-components';

// app
import Lang from '../../components/app/language';
import Url from '../../components/app/url';

// components
import Container from '../../components/container';
import Image from '../../components/image';

// styled
export const Solutions = styled.section`
	background: rgba(255, 255, 255, 1);
	padding: 5rem 0;
`;

export const Row = styled.div`
	align-items: center;
	margin: 0 -1rem;
	flex-flow: row;
	display: flex;

	@media all and (max-width: 1024px) {
		flex-flow: column;
		margin: -1rem 0;
	}
`;

export const Col = styled.div`
	flex-flow: column;
	padding: 0 1rem;
	display: flex;

	width: ${p => p.w || '50%'};

	&:last-child {
		padding-left: 3rem;
	}

	@media all and (max-width: 1024px) {
		max-width: 30rem;
		padding: 1rem 0;
		margin: 0 auto;
		width: 100%;

		&:last-child {
			padding-left: 0;
		}
	}
`;

export const ImgBox = styled.div`
	position: relative;

	& > img {
		display: block;
		width: 100%;
	}
`;

export const Title = styled.h2`
	margin: auto 0 3rem;

	padding-bottom: 1rem;
	position: relative;
	font-size: 3rem;

	&::after {
		background: rgba(0, 161, 120, 1);
		position: absolute;
		height: 0.125rem;
		content: '';
		width: 5rem;
		top: 100%;
		left: 0;
	}
`;

export const List = styled.ul`
	list-style: none;
	display: block;
`;

export const ListItem = styled.li`
	font-size: 1.2rem;
	display: block;

	&:not(:last-child) {
		margin-bottom: 3rem;
	}
`;

// default
export default props => {
	return (
		<Solutions {...props}>
			<Container>
				<Row>
					<Col w='55%'>
						<ImgBox>
							<Image src={Url('img/responsive.png')} />
						</ImgBox>
					</Col>

					<Col w='45%'>
						<Title>
							<Lang id='home.solutions.title' />
						</Title>

						<List>
							<ListItem>
								<Lang id='home.solutions.item.description' />
							</ListItem>
						</List>
					</Col>
				</Row>
			</Container>
		</Solutions>
	);
};
