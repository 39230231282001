// npm
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

// app
import Lang from '../../components/app/language';

// style
export const Footer = styled.footer`
	background: rgba(38, 38, 38, 1);
	padding: 2rem 0;
`;

export const Container = styled.div`
	position: relative;
	max-width: 80rem;
	margin: 0 auto;
	width: 90%;
`;

export const Credits = styled.div`
	color: rgba(255, 255, 255, 1);
	text-align: center;
`;

// default
export default () => {
	const year = moment().year();

	return (
		<Footer>
			<Container>
				<Credits>
					<b>DocDo</b> &copy; 2016 - {year}. <Lang id='home.footer.credits' />
				</Credits>
			</Container>
		</Footer>
	);
};
