// npm
import { createStore } from 'redux';

// actions
export const SET_PATH = 'SET_PATH';
export const setPath = value => ({ type: SET_PATH, value });

export const SET_LANG = 'SET_LANG';
export const setLang = value => ({ type: SET_LANG, value });

export const SET_MOBILE = 'SET_MOBILE';
export const setMobile = value => ({ type: SET_MOBILE, value });

export const SET_POPUP_HOME = 'SET_POPUP_HOME';
export const setPopupHome = value => ({ type: SET_POPUP_HOME, value });

export const SET_SCROLL_HOME = 'SET_SCROLL_HOME';
export const setScrollHome = value => ({ type: SET_SCROLL_HOME, value });

export const SET_MENU_ACTIVE = 'SET_MENU_ACTIVE';
export const setMenuActive = value => ({ type: SET_MENU_ACTIVE, value });

export const TOGGLE_MENU_MOBILE_VISIBLE = 'TOGGLE_MENU_MOBILE_VISIBLE';
export const toggleMenuMobileVisible = () => ({ type: TOGGLE_MENU_MOBILE_VISIBLE });

// state
const STATE = {
	path: [],
	lang: false,
	mobile: false,
	popup: {
		home: false,
	},
	scroll: {
		home: 0,
	},
	menu: {
		active: false,
		mobile: {
			visible: false,
		},
	},
};

// default
export default createStore((state = STATE, action) => {
	switch (action.type) {
		case SET_PATH: {
			return {
				...state,
				path: action.value || [],
			};
		}

		case SET_LANG: {
			return {
				...state,
				lang: action.value || false,
			};
		}

		case SET_MOBILE: {
			return {
				...state,
				mobile: action.value || false,
			};
		}

		case SET_SCROLL_HOME: {
			return {
				...state,
				scroll: {
					...state.scroll,
					home: action.value || 0,
				},
			};
		}

		case SET_POPUP_HOME: {
			return {
				...state,
				popup: {
					...state.popup,
					home: action.value || false,
				},
			};
		}

		case SET_MENU_ACTIVE: {
			return {
				...state,
				menu: {
					...state.menu,
					active: action.value || false,
				},
			};
		}

		case TOGGLE_MENU_MOBILE_VISIBLE: {
			return {
				...state,
				menu: {
					...state.menu,
					mobile: {
						...state.menu.mobile,
						visible: !state.menu.mobile.visible,
					},
				},
			};
		}

		default: {
			return state;
		}
	}
});
