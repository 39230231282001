// npm
import React, { Component } from 'react';
import styled from 'styled-components';
import uuid from 'uuid/v4';

// assets
import ImageShow from '../../assets/img/mobile.png';
import ImagePrev from '../../assets/img/prev.svg';
import ImageNext from '../../assets/img/next.svg';

// app
import Lang from '../../components/app/language';
import Url from '../../components/app/url';

// components
import Container from '../../components/container';
import Image from '../../components/image';

// styled
export const Information = styled.section`
	background: rgba(255, 255, 255, 1);
	padding: 5rem 0 7rem;
	overflow: hidden;
`;

export const Title = styled.h2`
	margin: 0 auto 6rem;
	text-align: center;
	font-size: 3rem;
`;

export const SliderBox = styled.div`
	position: relative;

	@media all and (max-width: 425px) {
		margin-bottom: 8rem;
	}
`;

export const SliderOverlay = styled.div`
	transform: translate(-50%, -50%);
	pointer-events: none;
	position: absolute;
	z-index: 200;
	left: 50%;
	top: 50%;

	& > img {
		transform: translate(1.45%, 1.75%);

		@media all and (max-width: 425px) {
			width: 310px;
		}
	}
`;

export const Slider = styled.div`
	justify-content: center;
	align-items: center;
	position: relative;
	flex-flow: row;
	display: flex;
	z-index: 100;

	& > .flipster__button {
		box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
		background: rgba(255, 255, 255, 0.85);
		transform: translateY(-50%);
		border-radius: 0.5rem;
		position: absolute;
		height: 5rem;
		width: 3rem;
		top: 50%;

		@media all and (max-width: 425px) {
			transform: translateY(6rem);
			top: 100%;
		}
	}

	& > .flipster__button::after {
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;

		position: absolute;
		height: 100%;
		content: '';
		width: 100%;
		left: 0;
		top: 0;
	}

	& > .flipster__button--prev {
		left: 0;

		@media all and (max-width: 425px) {
			left: 25%;
		}
	}

	& > .flipster__button--prev::after {
		background-image: url(${ImagePrev});
	}

	& > .flipster__button--next {
		right: 0;

		@media all and (max-width: 425px) {
			right: 25%;
		}
	}

	& > .flipster__button--next::after {
		background-image: url(${ImageNext});
	}
`;

export const SliderList = styled.ul`
	transform: translateX(50%) !important;
	list-style: none;
	display: block;
	width: 100%;
`;

export const SliderItem = styled.li`
	transition: 0.3s ease-in-out;
	transition-property: filter transform;

	position: absolute;
	max-width: 270px;
	width: 100%;

	@media all and (max-width: 425px) {
		width: 245px;
	}

	& > div {
		padding-bottom: 181.481%;
		position: relative;
		width: 100%;

		& > img {
			position: absolute;
			object-fit: cover;
			height: 100%;
			width: 100%;
			left: 0;
			top: 0;
		}
	}
`;

// imagens
const Images = () => {
	let rtn = [];

	for (let i = 1; i <= 15; i++) {
		rtn.push(
			<SliderItem key={i}>
				<Image src={Url(`img/app/${`${i}`.padStart(2, '0')}.webp`)} />
			</SliderItem>,
		);
	}

	return rtn;
};

// default
export default class extends Component {
	slideId = '';

	constructor(props) {
		super(props);

		this.slideId = uuid();
	}

	componentDidMount = () => {
		let timer = setInterval(() => {
			if (typeof window.$ !== 'undefined') {
				clearInterval(timer);

				let el = window.$(`#${this.slideId}`);

				const updatePosition = () => {
					setTimeout(() => {
						let items = el.find('.flipster__item');

						items.each((i, o) => {
							let item = window.$(o);
							let match = false;

							let pos = 0,
								transform = 0,
								scale = 0;

							if ((match = /.*?--past-([0-9]+)/g.exec(o.className)) !== null) {
								pos = Number(match[1]);
								transform = pos * -107.5;
								scale = 1 - pos * 0.1;
							} else if ((match = /.*?--current/g.exec(o.className)) !== null) {
								scale = 1;
							} else if ((match = /.*?--future-([0-9]+)/g.exec(o.className)) !== null) {
								pos = Number(match[1]);
								transform = pos * 107.5;
								scale = 1 - pos * 0.1;
							}

							transform -= 50;

							item.css({
								transform: `translateX(${transform}%) scale(${scale})`,
								'pointer-events': pos < 2 ? 'all' : 'none',
								filter: `blur(${pos < 2 ? 0 : (pos - 1) * 2}px)`,
								cursor: pos < 2 ? 'pointer' : '',
							});
						});
					}, 50);
				};

				el.flipster({
					itemContainer: 'ul',
					itemSelector: 'li',
					style: 'coverflow',
					start: 0,
					keyboard: false,
					scrollwheel: false,
					touch: true,
					nav: false,
					loop: true,
					buttons: 'custom',
					buttonPrev: '',
					buttonNext: '',
					onItemSwitch: () => updatePosition(),
				});

				updatePosition();
			}
		}, 100);
	};

	render = () => {
		return (
			<Information {...this.props}>
				<Container>
					<Title>
						<Lang id='home.howitworks.title' />
					</Title>

					<SliderBox>
						<SliderOverlay>
							<Image src={ImageShow} />
						</SliderOverlay>

						<Slider id={this.slideId}>
							<SliderList>
								<Images />
							</SliderList>
						</Slider>
					</SliderBox>
				</Container>
			</Information>
		);
	};
}
