// npm
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// project
import store from './components/app/reducer';
import App from './components/app';

// render
ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);
