// npm
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

// project
import Home from '../../pages/home';
import Payment from '../../pages/payment';

// default
export default () => {
	const path = useSelector(state => state.path);

	switch (typeof path[0] !== 'undefined' ? path[0] : false) {
		case '/home': {
			return <Home />;
		}

		case '/payment': {
			return <Payment />;
		}

		default: {
			return <Redirect to='/home' />;
		}
	}
};
