// npm
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { GetTranslation } from './app/language';

// default
export default (data = new FormData(), api = '') => {
	let user = localStorage.getItem('user');
	let lang = localStorage.getItem('lang');

	if (api) {
		data.append('api', api);
	}

	if (user) {
		try {
			user = JSON.parse(user);

			if (typeof user.token !== 'undefined') {
				data.append('token', user.token);
			}
		} catch {
			user = false;
		}
	}

	if (lang) {
		data.append('lang', lang.split('-')[0]);
	}

	return new Promise(resolve => {
		axios({
			url: 'https://www.docdo.com.br/v3/process/api.php',
			method: 'POST',
			responseType: 'json',
			data: data,
		}).then(response => {
			if (typeof response.data !== 'undefined') {
				if (typeof response.data.error !== 'undefined' && response.data.error) {
					toast.error(response.data.error);
					resolve(false);
				} else if (typeof response.data.data !== 'object') {
					resolve(false);
				} else {
					resolve(response.data.data);
				}
			}
		});
	});
};

// loading
export const ShowLoader = (text = false, lang = false) => {
	return new Promise(resolve => {
		Swal.fire({
			title: text !== false ? text : GetTranslation('api.loading', lang),
			allowEnterKey: false,
			allowEscapeKey: false,
			allowOutsideClick: false,
			onBeforeOpen: () => Swal.showLoading(),
			onOpen: () => resolve(),
		});
	});
};
