// npm
import { css, createGlobalStyle } from 'styled-components';

// default
export default createGlobalStyle(
	css`
		@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i&display=swap');

		* {
			font-family: 'Roboto', sans-serif;
			box-sizing: border-box;
			line-height: 1.35;
			padding: 0;
			border: 0;
			margin: 0;
		}

		* > * {
			line-height: inherit;
			color: inherit;
			font: inherit;
		}

		*:focus,
		:active {
			outline: none;
		}

		html,
		body {
			font-size: 16px;
		}

		a,
		button {
			cursor: pointer;
		}
	`
);
