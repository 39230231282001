// npm
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// app
import { setLang } from './reducer';

// assets
import FlagBR from '../../assets/img/lang-ptbr.svg';
import FlagUS from '../../assets/img/lang-enus.svg';

// traduções
export const Translations = {
	// API
	'api.loading': {
		br: 'Enviando...',
		us: 'Sending...',
	},

	// menu
	'menu.home': {
		br: 'Home',
		us: 'Home',
	},
	'menu.solutions': {
		br: 'Soluções',
		us: 'Solutions',
	},
	'menu.company': {
		br: 'A Empresa',
		us: 'Company',
	},
	'menu.howitworks': {
		br: 'Como Funciona',
		us: 'How it Works',
	},
	'menu.contact': {
		br: 'Contato',
		us: 'Contact',
	},
	'menu.panel': {
		br: 'Acessar Painel',
		us: 'Access Panel',
	},

	// home
	// home.header
	'home.header.title': {
		br: 'Veja seus exames de imagem por um outro ângulo',
		us: 'View your image exams from another angle',
	},
	'home.header.description': {
		br: 'um programa revolucionário testado e aprovado por médicos de todo o mundo.',
		us: 'a revolutionary program tested and approved by doctors around the world.',
	},

	// home.solutions
	'home.solutions.title': {
		br: 'Ágil e resolutivo!',
		us: 'Agile and resolute!',
	},
	'home.solutions.item.description': {
		br: 'Docdo oferece a mobilidade multiplataformas para auxiliar médicos no planejamento de cirurgias de alta complexidade, seja por computador, tablet ou celular. O aplicativo transforma os exames de imagem convencional como TC ou RM em um ambiente 3d, no qual os órgãos do corpo humano são transformados em objetos tridimensionais facilitando a compreensão da anatomia cirúrgica, com toda a segurança dos seus dados que são completamente anonimizados.',
		us: 'Docdo offers multiplatform mobility to assist physicians in planning highly complex surgeries, whether by computer, tablet or mobile. The app transforms conventional imaging such as CT or MRI into a 3D environment in which the organs of the human body are transformed into three-dimensional objects making it easy to understand the surgical anatomy with all the security of your data that is completely anonymized.',
	},

	// home.company
	'home.company.title': {
		br: 'A Empresa',
		us: 'Company',
	},
	'home.company.description.1': {
		br: 'O aplicativo Docdo foi criado em 2015 pela empresa InfiniBrains. Formada pelos médicos Aurus Dourados, Bruno Aragão e pelos programadores Alexandre Tolstenko, Joel Oliveira e Rafael Dalmazo. Ao longo desta caminhada, a empresa esteve presente em vários congressos médicos conceituados em todo o mundo bem como destaque nacional como startup inovadora.',
		us: 'The Docdo application was created in 2015 by the company InfiniBrains. Formed by doctors Aurus Dourados, Bruno Aragão and programmers Alexandre Tolstenko, Joel Oliveira and Rafael Dalmazo. Throughout this journey, the company was present in several renowned medical congresses around the world as well as national prominence as an innovative startup.',
	},
	'home.company.description.2': {
		br: 'Além do aplicativo, em 2017 o Docdo foi um dos pioneiros do mundo criar esse ambiente 3D o óculos de realidade aumentada da Microsoft -  Hololens, sendo usado em seu primeiro caso para o auxílio intraoperatório à cirurgia minimamente invasiva de câncer renal.',
		us: "In addition to the app, in 2017 Docdo was one of the world's foremost creators of this 3D environment - Microsoft's augmented reality glasses - Hololens, being used in its first case for intraoperative aid for minimally invasive kidney cancer surgery.",
	},

	'home.company.youtube': {
		br: 'U9tHxlLQfQ4',
		us: '7eP_JQnIs28',
	},

	// home.howitworks
	'home.howitworks.title': {
		br: 'Como Funciona',
		us: 'How it Works',
	},

	// home.contact
	'home.contact.title': {
		br: 'Contato',
		us: 'Contact',
	},
	'home.contact.description': {
		br: 'Estamos à disposição. Nos envie uma mensagem que responderemos em 24h, exceto feriado e fim de semana.',
		us: "We know you've come here to talk to us. Just ask us and we'll reply with in 24 hours unless it is weekend.",
	},
	'home.contact.form.name': {
		br: 'Nome',
		us: 'Name',
	},
	'home.contact.form.name.description': {
		br: 'Insira seu nome completo',
		us: 'Insert your full name',
	},
	'home.contact.form.email': {
		br: 'E-mail',
		us: 'Email',
	},
	'home.contact.form.email.description': {
		br: 'Insira um e-mail válido para contato',
		us: 'Insert a valid email for contact',
	},
	'home.contact.form.phone': {
		br: 'Telefone',
		us: 'Phone',
	},
	'home.contact.form.phone.description': {
		br: 'Insira um telefone válido para contato',
		us: 'Insert a valid phone for contact',
	},
	'home.contact.form.message': {
		br: 'Mensagem',
		us: 'Message',
	},
	'home.contact.form.message.description': {
		br: 'Insira uma mensagem para nós',
		us: 'Inser a message for us',
	},
	'home.contact.form.submit': {
		br: 'Enviar',
		us: 'Submit',
	},

	// home.footer
	'home.footer.credits': {
		br: 'Todos os direitos reservados.',
		us: 'All rights reserved.',
	},

	// home.modal
	'home.modal.title.login': {
		br: 'Login',
		us: 'Login',
	},
	'home.modal.title.register': {
		br: 'Cadastre-se',
		us: 'Register',
	},
	'home.modal.title.recovery': {
		br: 'Recuperar Senha',
		us: 'Recover Password',
	},

	'home.modal.form.name': {
		br: 'Nome & Sobrenome',
		us: 'First and last names',
	},
	'home.modal.form.user': {
		br: 'Usuário',
		us: 'User',
	},
	'home.modal.form.password': {
		br: 'Senha',
		us: 'Password',
	},
	'home.modal.form.password2': {
		br: 'Repita a senha',
		us: 'Repeat the password',
	},
	'home.modal.form.email': {
		br: 'E-mail',
		us: 'Email',
	},

	'home.modal.form.login.submit': {
		br: 'Entrar',
		us: 'Enter',
	},
	'home.modal.form.register.submit': {
		br: 'Cadastrar',
		us: 'Register',
	},
	'home.modal.form.recovery.submit': {
		br: 'Recuperar',
		us: 'Recover',
	},

	'home.modal.form.login.success': {
		br: 'Seja bem-vindo(a)!',
		us: 'Welcome!',
	},

	'home.modal.form.recovery.error': {
		br: 'Algo deu errado, tente novamente!',
		us: 'Something went wrong, try again!',
	},
	'home.modal.form.recovery.success': {
		br: 'Cheque seu e-mail para recuperar sua senha!',
		us: 'Check your email in order to recover your password!',
	},

	'contact.form.warn.name': {
		br: 'Preencha o campo do nome!',
		us: "Fill the name's field!",
	},
	'contact.form.warn.emailorphone': {
		br: 'Preencha o campo do e-mail ou telefone!',
		us: 'Fill the email or phone field!',
	},
	'contact.form.warn.message': {
		br: 'Preencha o campo da mensagem!',
		us: "Fill the message's field!",
	},

	'contact.form.response.success': {
		br: 'Contato enviado com sucesso!',
		us: 'Contact sent successfully!',
	},
	'contact.form.response.error': {
		br: 'Algo deu errado, tente novamente!',
		us: 'Something went wrong, try again!',
	},
};

// options
export const Options = [
	{
		id: 'br',
		name: 'Português',
		lang: 'pt-BR',
		flag: FlagBR,
	},
	{
		id: 'us',
		name: 'English',
		lang: 'en-US',
		flag: FlagUS,
	},
];

// arruma lang
export const LangFixer = () => {
	const dispatch = useDispatch();
	const lang = useSelector(s => s.lang);

	if (lang === false) {
		let userLang = localStorage.getItem('lang') || navigator.language;

		userLang = Options.find(a => a.lang === userLang);

		if (userLang) {
			dispatch(setLang(userLang));
		} else {
			dispatch(setLang(Options[0]));
		}
	}

	return null;
};

// retorna tradução
export const GetTranslation = (id, lang) => {
	if (lang && typeof Translations[id] !== 'undefined' && typeof Translations[id][lang.id] !== 'undefined') {
		return Translations[id][lang.id];
	} else {
		return 'INVALID';
	}
};

// default
export default ({ id }) => {
	const lang = useSelector(s => s.lang);

	if (lang && typeof Translations[id] !== 'undefined' && typeof Translations[id][lang.id] !== 'undefined') {
		return <>{Translations[id][lang.id]}</>;
	} else {
		return <>{'INVALID'}</>;
	}
};
