// npm
import React, { Component } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

// assets
import assetClose from '../../../assets/img/close.svg';

// app
import { setPopupHome } from '../../../components/app/reducer';

// components
import Image from '../../../components/image';
import Login from './login';
import Register from './register';
import Recovery from './recovery';

// style
export const Container = styled.div`
	transition: opacity 0.3s ease-in-out;

	background: rgba(191, 191, 191, 0.9);
	pointer-events: none;
	overflow-y: scroll;
	overflow-x: auto;
	position: fixed;
	z-index: 9000;
	height: 100%;
	width: 100%;
	opacity: 0;
	left: 0;
	top: 0;

	&.open {
		pointer-events: all;
		opacity: 1;
	}

	&.busy {
		pointer-events: none;
	}
`;

export const Inside = styled.div`
	justify-content: center;
	align-items: center;
	flex-flow: column;
	min-height: 100%;
	min-width: 100%;
	display: flex;
`;

export const Modal = styled.div`
	transition: opacity 0.3s ease-in-out;

	box-shadow: 0 0 2rem rgba(0, 0, 0, 0.4);
	background: rgba(64, 64, 64, 1);
	padding: 3rem 2rem 2rem;
	border-radius: 0.5rem;
	position: relative;
	margin: 3rem auto;
	max-width: 32rem;
	width: 90%;

	.busy & {
		opacity: 0.5;
	}
`;

export const Close = styled.button`
	background: transparent;
	position: absolute;
	height: 1rem;
	right: 1rem;
	width: 1rem;
	top: 1rem;

	& > img {
		object-position: center;
		object-fit: contain;
		height: 1rem;
		width: 1rem;
	}
`;

export const Body = styled.div`
	display: block;
`;

// active
const GetActive = ({ active, changeForm, toggleBusy }) => {
	switch (active) {
		case 'register': {
			return <Register changeForm={changeForm} toggleBusy={toggleBusy} />;
		}

		case 'recovery': {
			return <Recovery changeForm={changeForm} toggleBusy={toggleBusy} />;
		}

		default: {
			return <Login changeForm={changeForm} toggleBusy={toggleBusy} />;
		}
	}
};

// render
export const Render = ({ active, busy, changeForm, toggleBusy }) => {
	const dispatch = useDispatch();
	const open = useSelector(s => s.popup.home);

	const closeModal = e => {
		e.preventDefault();

		dispatch(setPopupHome(false));
	};

	return (
		<Container className={classnames({ open, busy })} onClick={e => closeModal(e)}>
			<Inside>
				<Modal onClick={e => e.stopPropagation()}>
					<Close onClick={e => closeModal(e)}>
						<Image src={assetClose} />
					</Close>

					<Body>
						<GetActive active={active} changeForm={changeForm} toggleBusy={toggleBusy} />
					</Body>
				</Modal>
			</Inside>
		</Container>
	);
};

// default
export default class extends Component {
	_mounted = false;

	state = {
		active: false,
		busy: false,
	};

	componentDidMount = () => (this._mounted = true);

	componentWillUnmount = () => (this._mounted = false);

	changeForm = (e, active) => {
		e.preventDefault();

		if (this._mounted) {
			this.setState({ active });
		}
	};

	toggleBusy = busy => {
		if (this._mounted) {
			this.setState({ busy });
		}
	};

	render = () => {
		const { active, busy } = this.state;

		return <Render active={active} busy={busy} changeForm={this.changeForm} toggleBusy={this.toggleBusy} />;
	};
}
