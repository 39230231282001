// npm
import React, { Component } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

// app
import Lang, { GetTranslation } from '../../../components/app/language';
import Url from '../../../components/app/url';

// components
import API from '../../../components/api';
import Captcha from '../../../components/captcha';

// style
export const Box = styled.div`
	transition: opacity 0.5s ease-in-out;
	opacity: 0;

	&.show {
		opacity: 1;
	}
`;

export const Title = styled.h2`
	color: rgba(255, 255, 255, 1);
	margin-bottom: 2rem;
	text-align: center;
	font-size: 2rem;
	display: block;
	width: 100%;
`;

export const Form = styled.form`
	flex-flow: column;
	display: flex;
`;

export const Group = styled.div`
	flex: 0 0 auto;

	margin-bottom: 1.5rem;
	flex-flow: column;
	display: flex;

	&.recaptcha > div {
		margin: 0 auto;
	}
`;

export const Label = styled.label`
	flex: 0 0 auto;

	color: rgba(255, 255, 255, 1);
	margin: 0 auto 0.25rem 0;
	display: block;
`;

export const Input = styled.input`
	flex: 0 0 auto;

	background: rgba(255, 255, 255, 1);
	border-radius: 0.25rem;
	padding: 0 1rem;
	height: 3rem;
	width: 100%;
`;

export const Button = styled.button`
	transition: 0.3s ease-in-out;
	transition-property: background border-color;

	border: 0.125rem solid rgba(255, 255, 255, 1);
	background: rgba(0, 161, 120, 0);
	color: rgba(255, 255, 255, 1);
	border-radius: 2rem;
	padding: 0 2rem;
	display: block;
	margin: 0 auto;
	height: 3rem;

	&:hover {
		border-color: rgba(0, 161, 120, 1);
		background: rgba(0, 161, 120, 1);
	}
`;

export const Nav = styled.div`
	justify-content: space-between;
	align-items: center;
	margin-top: 2rem;
	flex-flow: row;
	display: flex;
`;

export const NavButton = styled.button`
	color: rgba(255, 255, 255, 1);
	background: transparent;
	display: block;
`;

// default
const Render = ({ changeForm, toggleBusy }) => {
	const lang = useSelector(s => s.lang);

	const formSubmit = e => {
		e.preventDefault();

		toggleBusy(true);

		let form = e.currentTarget;
		let data = new FormData(form);

		API(data, 'login').then(user => {
			if (typeof user.token !== 'undefined') {
				window.localStorage.setItem('user', JSON.stringify(user));

				toast.success(GetTranslation('home.modal.form.login.success', lang));

				form.reset();

				window.location.href = Url(`painel/`);
			}

			toggleBusy(false);
		});
	};

	return (
		<>
			<Title>
				<Lang id='home.modal.title.login' />
			</Title>

			<Form onSubmit={formSubmit.bind(this)}>
				<Group>
					<Label htmlFor='login.user'>
						<Lang id='home.modal.form.user' />
					</Label>
					<Input type='text' name='login' id='login.user' />
				</Group>

				<Group>
					<Label htmlFor='login.password'>
						<Lang id='home.modal.form.password' />
					</Label>
					<Input type='password' name='password' id='login.password' />
				</Group>

				<Group className='recaptcha'>
					<Captcha />
				</Group>

				<Button>
					<Lang id='home.modal.form.login.submit' />
				</Button>
			</Form>

			<Nav>
				<NavButton onClick={e => changeForm(e, 'recovery')}>
					<Lang id='home.modal.title.recovery' />
				</NavButton>

				<NavButton onClick={e => changeForm(e, 'register')}>
					<Lang id='home.modal.title.register' />
				</NavButton>
			</Nav>
		</>
	);
};

// main
export class Main extends Component {
	_mounted = false;

	state = {
		show: false,
	};

	componentDidMount = () => {
		this._mounted = true;

		setTimeout(() => {
			if (this._mounted) {
				this.setState({ show: true });
			}
		}, 100);
	};

	componentWillUnmount = () => (this._mounted = false);

	render = () => {
		const { show } = this.state;

		return <Box {...this.props} show={show} className={classnames({ show })} />;
	};
}

// default
export default props => {
	return (
		<Main>
			<Render {...props} />
		</Main>
	);
};
