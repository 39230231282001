// npm
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuActive } from '../../components/app/reducer';
import classnames from 'classnames';
import $ from 'jquery';

// project
import { setScrollHome } from '../../components/app/reducer';
import Page from '../../components/page';
import Navbar from '../../components/navbar';
import Header from './header';
import Solutions from './solutions';
import Company from './company';
import HowItWorks from './how-it-works';
import Contact from './contact';
import Footer from './footer';
import Modal from './modal';

// default
export default () => {
	const dispatch = useDispatch();
	const modalOpen = useSelector(s => s.popup.home);

	let page = false,
		navbar = false;

	const pageOnScroll = () => {
		if (page === false) page = $('#page');

		dispatch(setScrollHome(page.scrollTop()));

		let dist = '';

		['#home', '#solutions', '#company', '#how-it-works', '#contact'].forEach(id => {
			if (navbar === false) navbar = $('#navbar');

			let top = $(id).offset().top;

			if (top <= navbar.outerHeight() * 2) {
				dist = id;
			}
		});

		dispatch(setMenuActive(dist));
	};

	setTimeout(() => {
		pageOnScroll();
	}, 100);

	return (
		<>
			<Page id='page' className={classnames({ modalOpen })} onScroll={pageOnScroll}>
				<Navbar />

				<Header id='home' />

				<Solutions id='solutions' />

				<Company id='company' />

				<HowItWorks id='how-it-works' />

				<Contact id='contact' />

				<Footer />
			</Page>

			<Modal />
		</>
	);
};
