// npm
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

// app
import Lang, { GetTranslation } from '../../../components/app/language';
import { ShowLoader } from '../../../components/api';
import Url from '../../../components/app/url';

// style
class Style {
	static Form = styled.form`
		flex: 0 0 auto;

		width: 100%;
	`;

	static Group = styled.div`
		margin-bottom: 1.5rem;

		&:last-child {
			text-align: center;
			margin-bottom: 0;
		}
	`;

	static Label = styled.label`
		color: rgba(255, 255, 255, 1);
		margin-bottom: 0.25rem;
		display: inline-block;
	`;

	static Input = styled.input`
		border-bottom: 0.0625rem solid rgba(255, 255, 255, 1);
		color: rgba(255, 255, 255, 1);
		background: transparent;
		border-radius: 0;
		padding: 0 1rem;
		height: 3rem;
		width: 100%;

		&::placeholder {
			color: rgba(255, 255, 255, 0.8);
		}
	`;

	static Text = styled.textarea`
		border-bottom: 0.0625rem solid rgba(255, 255, 255, 1);
		color: rgba(255, 255, 255, 1);
		background: transparent;
		border-radius: 0;
		min-height: 8rem;
		resize: vertical;
		padding: 1rem;
		width: 100%;

		&::placeholder {
			color: rgba(255, 255, 255, 0.8);
		}
	`;

	static Button = styled.button`
		transition: 0.3s ease-in-out;
		transition-property: background color;

		border: 0.125rem solid rgba(255, 255, 255, 1);
		color: rgba(255, 255, 255, 1);
		background: transparent;
		display: inline-block;
		border-radius: 2rem;
		padding: 0 2rem;
		height: 3rem;

		&:hover {
			background: rgba(255, 255, 255, 1);
			color: rgba(0, 161, 120, 1);
		}
	`;
}

// default
export default () => {
	const lang = useSelector(state => state.lang);

	const formSubmit = e => {
		e.preventDefault();

		let form = e.currentTarget;

		let data = new FormData(form);

		let name = data.get('name');
		let email = data.get('email');
		let phone = data.get('phone');
		let message = data.get('message');

		let valid = {
			name: name.match(/^.{1,}$/g),
			email: email.match(/^.{1,}$/g),
			phone: phone.match(/^.{1,}$/g),
			message: message.match(/^.{1,}$/g),
		};

		if (!valid.name) {
			toast.warn(<Lang id='contact.form.warn.name' />);
		} else if (!valid.email && !valid.phone) {
			toast.warn(<Lang id='contact.form.warn.emailorphone' />);
		} else if (!valid.message) {
			toast.warn(<Lang id='contact.form.warn.message' />);
		} else {
			ShowLoader(false, lang).then(() => {
				Axios({
					url: Url('api/contact'),
					method: 'POST',
					responseType: 'json',
					data: data,
				})
					.then(({ data }) => {
						if (typeof data.success !== 'undefined') {
							toast.success(<Lang id={data.success} />);
							form.reset();
						} else if (typeof data.error !== 'undefined') {
							toast.error(<Lang id={data.error} />);
						}
					})
					.catch(() => {})
					.finally(() => Swal.close());
			});
		}
	};

	const inputs = {
		name: {
			Component: () => <Lang id='home.contact.form.name' />,
			placeholder: GetTranslation('home.contact.form.name.description', lang),
		},

		email: {
			Component: () => <Lang id='home.contact.form.email' />,
			placeholder: GetTranslation('home.contact.form.email.description', lang),
		},

		phone: {
			Component: () => <Lang id='home.contact.form.phone' />,
			placeholder: GetTranslation('home.contact.form.phone.description', lang),
		},
	};

	return (
		<Style.Form onSubmit={e => formSubmit(e)}>
			{Object.entries(inputs).map(([name, input]) => (
				<Style.Group key={name}>
					<Style.Label>
						<input.Component />
					</Style.Label>

					<Style.Input name={name} placeholder={input.placeholder} />
				</Style.Group>
			))}

			<Style.Group>
				<Style.Label>
					<Lang id='home.contact.form.message' />
				</Style.Label>

				<Style.Text name='message' placeholder={GetTranslation('home.contact.form.message.description', lang)} />
			</Style.Group>

			<Style.Group>
				<Style.Button>
					<Lang id='home.contact.form.submit' />
				</Style.Button>
			</Style.Group>
		</Style.Form>
	);
};
