// npm
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

// app
import Lang, { GetTranslation } from '../../components/app/language';

// components
import Container from '../../components/container';
import Url from '../../components/app/url';
import Image from '../../components/image';

// styled
export const Company = styled.section`
	background: rgba(255, 255, 255, 1);
	position: relative;
	padding: 5rem 0;
`;

export const Background = styled.div`
	clip-path: polygon(0 0, 100% 5rem, 100% 100%, 0 calc(100% - 5rem));
	background: rgba(0, 161, 120, 1);
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;

	& > img {
		object-fit: cover;
		position: fixed;
		display: block;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;

		&.loaded {
			opacity: 0.5;
		}
	}

	@media all and (max-width: 1024px) {
		clip-path: polygon(0 0, 100% 2.5rem, 100% 100%, 0 calc(100% - 2.5rem));
	}
`;

export const Row = styled.div`
	margin: 0 -1rem;
	flex-flow: row;
	display: flex;

	@media all and (max-width: 1024px) {
		flex-flow: column;
		margin: -1rem 0;
	}
`;

export const Col = styled.div`
	flex-flow: column;
	padding: 0 1rem;
	display: flex;

	width: ${p => p.w || '50%'};

	&:first-child {
		padding-right: 3rem;
	}

	&:last-child {
		margin: auto 0;
	}

	@media all and (max-width: 1024px) {
		max-width: 30rem;
		padding: 1rem 0;
		margin: 0 auto;
		width: 100%;

		&:first-child {
			padding-right: 0;
			order: 2;
		}

		&:last-child {
			margin: 0 auto;
			order: 1;
		}
	}
`;

export const VideoBox = styled.div`
	background: rgba(240, 240, 240, 1);
	padding-bottom: 100%;
	position: relative;
	height: 0;

	& > iframe {
		object-fit: contain;
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
	}
`;

export const Title = styled.h2`
	margin: auto 0 3rem;

	color: rgba(255, 255, 255, 1);
	padding-bottom: 1rem;
	position: relative;
	font-size: 3rem;

	&::after {
		background: rgba(255, 255, 255, 1);
		position: absolute;
		height: 0.125rem;
		content: '';
		width: 5rem;
		top: 100%;
		left: 0;
	}
`;

export const Description = styled.p`
	color: rgba(255, 255, 255, 1);
	margin-bottom: auto;
	text-align: justify;
	font-size: 1.2rem;
	line-height: 1.5;

	@media all and (max-width: 1024px) {
		margin-bottom: 0;
	}
`;

// default
export default props => {
	const lang = useSelector(s => s.lang);

	const video = GetTranslation('home.company.youtube', lang);

	return (
		<Company {...props}>
			<Background>
				<Image src={Url('img/bg.webp')} />
			</Background>

			<Container>
				<Row>
					<Col w='45%'>
						<Title>
							<Lang id='home.company.title' />
						</Title>

						<Description>
							<Lang id='home.company.description.1' />
							<br />
							<br />
							<Lang id='home.company.description.2' />
						</Description>
					</Col>

					<Col w='55%'>
						<VideoBox>
							<iframe title='docdo-youtube-video' src={`https://www.youtube.com/embed/${video}`} frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
						</VideoBox>
					</Col>
				</Row>
			</Container>
		</Company>
	);
};
