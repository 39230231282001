// npm
import React, { useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

// components
import Page from '../../components/page';
import Logo from './logo';
import Box from './box';

// style
class Style {
	static Container = styled.div`
		background: rgba(33, 33, 33, 1);
		min-height: 100%;
		overflow: hidden;
		width: 100%;
	`;

	static Content = styled(animated.div)`
		padding-bottom: 5rem;
		flex-flow: column;
		display: flex;
		width: 100%;
	`;
}

// default
export default () => {
	const [ready, setReady] = useState(false);

	const contentStyle = useSpring({
		transform: `translateY(${ready ? 0 : -2}rem)`,
		opacity: ready ? 1 : 0,
	});

	return (
		<Page>
			<Style.Container>
				<Style.Content style={contentStyle}>
					<Logo onLoad={() => setReady(true)} />
					<Box />
				</Style.Content>
			</Style.Container>
		</Page>
	);
};
