// npm
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import classnames from 'classnames';

// project
import { Navbar } from '../navbar';

// styled
export const Page = styled.div`
	background: rgba(255, 255, 255, 1);
	overflow-y: scroll;
	overflow-x: auto;
	position: fixed;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;

	&.mobile {
		overflow-y: hidden;
	}

	& > * {
		transition: filter 0.3s ease-in-out;
	}

	&.mobile > *:not(${Navbar}) {
		filter: brightness(0.2);
		pointer-events: none;
	}

	&.modalOpen {
		pointer-events: none;
	}
`;

// default
export default props => {
	const mobileVisible = useSelector(s => s.menu.mobile.visible);

	return <Page {...props} className={classnames({ mobile: mobileVisible })} />;
};
