// npm
import styled from 'styled-components';

// tamanhos
const sizes = {
	lg: '100rem',
	sm: '60rem',
	xs: '40rem'
};

// styled
export default styled.div`
	position: relative;
	display: block;
	margin: 0 auto;
	width: 90%;

	max-width: ${({ size }) => (size ? sizes[size] : '80rem')};
`;
