// npm
import React from 'react';
import styled from 'styled-components';

// components
import Image from '../../components/image';

// assets
import LogoAsset from '../../assets/img/logo.png';

// style
class Style {
	static Logo = styled.div`
		flex: 0 0 auto;

		padding: 3rem 2rem;
		flex-flow: row;
		display: flex;

		& > img {
			flex: 0 0 auto;

			max-width: 10rem;
			display: block;
			margin: 0 auto;
			width: 100%;
		}
	`;
}

// default
export default ({ onLoad = false }) => {
	return (
		<Style.Logo>
			<Image src={LogoAsset} onLoad={onLoad} />
		</Style.Logo>
	);
};
