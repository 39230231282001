// npm
import React from 'react';
import styled from 'styled-components';

// components
import Container from '../../../components/container';
import Form from './form';

// app
import Lang from '../../../components/app/language';
import Url from '../../../components/app/url';
import Image from '../../../components/image';

// styled
class Style {
	static Company = styled.section`
		background: rgba(255, 255, 255, 1);
		position: relative;
		padding: 5rem 0;
	`;

	static Background = styled.div`
		clip-path: polygon(0 0, 100% 5rem, 100% 100%, 0 100%);
		background: rgba(0, 161, 120, 1);
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;

		& > img {
			object-fit: cover;
			position: fixed;
			display: block;
			height: 100%;
			width: 100%;
			left: 0;
			top: 0;

			&.loaded {
				opacity: 0.5;
			}
		}

		@media all and (max-width: 1024px) {
			clip-path: polygon(0 0, 100% 2.5rem, 100% 100%, 0 100%);
		}
	`;

	static Title = styled.h2`
		color: rgba(255, 255, 255, 1);
		margin-bottom: 2rem;
		text-align: center;
		font-size: 3rem;
	`;

	static Description = styled.p`
		color: rgba(255, 255, 255, 1);
		margin-bottom: 2rem;
		text-align: center;
	`;

	static Row = styled.div`
		flex: 0 0 auto;

		justify-content: space-around;
		flex-flow: row;
		display: flex;
	`;

	static Col = styled.div`
		flex: 0 0 auto;

		flex-flow: column;
		max-width: 30rem;
		margin: auto 0;
		display: flex;
		width: 100%;
	`;
}

// default
export default props => {
	return (
		<Style.Company {...props}>
			<Style.Background>
				<Image src={Url('img/bg.webp')} />
			</Style.Background>

			<Container>
				<Style.Title>
					<Lang id='home.contact.title' />
				</Style.Title>

				<Style.Description>
					<Lang id='home.contact.description' />
				</Style.Description>

				<Style.Row>
					<Style.Col>
						<Form />
					</Style.Col>
				</Style.Row>
			</Container>
		</Style.Company>
	);
};
