// npm
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

// app
import { LangFixer } from './language';
import Style from './styled';
import Icon from './icon';
import Paths from './paths';
import Pages from './pages';

// default
export default () => {
	return (
		<>
			<LangFixer />

			<Style />

			<Icon />

			<ToastContainer />

			<BrowserRouter basename='/v4'>
				<Route render={({ location }) => <Paths pathname={location.pathname} />} />

				<Pages />
			</BrowserRouter>
		</>
	);
};
