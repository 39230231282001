// npm
import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

// app
import { Options } from '../app/language';
import { setLang } from '../app/reducer';

// components
import Image from '../image';

// style
export const Box = styled.div`
	flex: 0 0 auto;

	margin: auto 0;
	margin-right: 1rem;

	position: relative;
	display: block;

	&.mobile {
		margin: 0 0 1.5rem;
		flex-flow: column;
		display: flex;
	}
`;

export const Button = styled.label`
	background: transparent;
	border-radius: 2rem;
	overflow: hidden;
	cursor: pointer;
	display: block;
	height: 1.5rem;
	width: 1.5rem;

	& > img {
		display: block;
		height: 100%;
		width: 100%;
	}

	.mobile & {
		margin: 0 auto;
		flex: 0 0 auto;
	}
`;

export const DropDown = styled.div`
	box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.25);
	background: rgba(255, 255, 255, 1);
	border-radius: 0.5rem;
	margin-top: 0.5rem;
	position: absolute;
	padding: 0.5rem 0;
	width: 10rem;
	top: 100%;
	right: 0;

	&:not(.mobile) {
		transition: 0.3s ease-in-out;
		transition-property: opacity transform;

		transform: translate(0, 1rem);
		pointer-events: none;
		opacity: 0;
	}

	&.mobile {
		margin: 0.5rem auto 0;
		position: relative;
		right: initial;
		top: initial;
	}

	&.open:not(.mobile) {
		pointer-events: all;
		transform: initial;
		opacity: 1;
	}
`;

export const DropDownItem = styled.button`
	transition: 0.3s ease-in-out;
	transition-property: background color;

	background: transparent;
	padding: 0.5rem 1rem;
	flex-flow: row;
	display: flex;
	width: 100%;

	&:not(:last-child) {
		margin-bottom: 0.25rem;
	}

	&.active {
		background: rgba(0, 161, 120, 1);
		color: rgba(255, 255, 255, 1);
	}

	& > img {
		flex: 0 0 auto;

		margin: auto 0;
		margin-right: 1rem;

		border: 0.125rem solid rgba(255, 255, 255, 1);
		object-position: center;
		border-radius: 2rem;
		object-fit: cover;
		height: 1.75rem;
		width: 1.75rem;
	}
`;

export const DropDownName = styled.span`
	flex: 1 1 auto;

	text-align: left;
	margin: auto 0;
`;

// default
export default ({ mobile = false }) => {
	const dispatch = useDispatch();
	const lang = useSelector(s => s.lang);

	const [open, setOpen] = useState(false);

	const languageSelection = (e, item) => {
		e.preventDefault();

		dispatch(setLang(item));

		localStorage.setItem('lang', item.lang);
	};

	return (
		<Box className={classnames({ mobile })}>
			<Button onClick={() => setOpen(!open)}>
				<Image src={lang.flag} />
			</Button>

			<DropDown className={classnames({ mobile, open })}>
				{Options.map((item, i) => (
					<DropDownItem key={i} className={classnames({ active: lang.id === item.id })} onClick={e => languageSelection(e, item)}>
						<Image src={item.flag} />
						<DropDownName>{item.name}</DropDownName>
					</DropDownItem>
				))}
			</DropDown>
		</Box>
	);
};
