// npm
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// project
import Container from '../container';
import Image from '../image';
import Menu from './menu';

// files
import LogoPng from '../../assets/img/logo.png';

// styled
export const Navbar = styled.header`
	position: sticky;
	z-index: 9000;
	width: 100%;
	height: 0;
	left: 0;
	top: 0;

	${Container} {
		flex-flow: row;
		display: flex;
		height: 6rem;
	}
`;

export const NavbarBack = styled.div`
	transition: 0.3s ease-in-out;
	transition-property: background;

	background: ${p => (p.top > 150 ? 'rgba(0, 0, 0, 0.95)' : 'transparent')};
`;

export const Logo = styled.div`
	flex: 0 0 auto;

	margin: auto;
	margin-left: 0;

	display: block;

	img {
		height: 3rem;
	}
`;

// default
export default () => {
	const top = useSelector(s => s.scroll.home);
	const mobileVisible = useSelector(s => s.menu.mobile.visible);

	return (
		<Navbar>
			<NavbarBack id='navbar' top={mobileVisible ? 999 : top}>
				<Container size='lg'>
					<Logo>
						<Image src={LogoPng} />
					</Logo>

					<Menu />
				</Container>
			</NavbarBack>
		</Navbar>
	);
};
