// npm
import React from 'react';
import { toast } from 'react-toastify';

// app
import Lang from '../../../components/app/language';

// component
import { Main, Title, Form, Group, Label, Input, Button, Nav, NavButton } from './login';
import API from '../../../components/api';
import Captcha from '../../../components/captcha';

// default
export default ({ changeForm, toggleBusy }) => {
	const formSubmit = e => {
		e.preventDefault();

		toggleBusy(true);

		let form = e.currentTarget;
		let data = new FormData(form);

		API(data, 'register').then(data => {
			if (typeof data.message !== 'undefined') {
				toast.success(data.message);

				form.reset();
			}

			toggleBusy(false);
		});
	};

	return (
		<Main>
			<Title>
				<Lang id='home.modal.title.register' />
			</Title>

			<Form onSubmit={formSubmit.bind(this)}>
				<Group>
					<Label>
						<Lang id='home.modal.form.name' />
					</Label>
					<Input type='text' name='name' />
				</Group>

				<Group>
					<Label>
						<Lang id='home.modal.form.user' />
					</Label>
					<Input type='text' name='login' />
				</Group>

				<Group>
					<Label>
						<Lang id='home.modal.form.password' />
					</Label>
					<Input type='password' name='password' />
				</Group>

				<Group>
					<Label>
						<Lang id='home.modal.form.password2' />
					</Label>
					<Input type='password' name='password2' />
				</Group>

				<Group>
					<Label>
						<Lang id='home.modal.form.email' />
					</Label>
					<Input type='email' name='email' />
				</Group>

				<Group className='recaptcha'>
					<Captcha />
				</Group>

				<Button>
					<Lang id='home.modal.form.register.submit' />
				</Button>
			</Form>

			<Nav>
				<NavButton onClick={e => changeForm(e, 'login')}>
					<Lang id='home.modal.title.login' />
				</NavButton>

				<NavButton onClick={e => changeForm(e, 'recovery')}>
					<Lang id='home.modal.title.recovery' />
				</NavButton>
			</Nav>
		</Main>
	);
};
