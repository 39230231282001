// npm
import React, { Component } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

// styled
const Image = styled.img`
	transition: opacity 0.5s ease-in-out;

	opacity: 0;

	&:not(.loaded) {
		visibility: hidden;
	}

	&.loaded {
		opacity: 1;
	}
`;

// default
export default class extends Component {
	_mounted = false;
	_loaded = false;

	state = { loaded: false };

	componentDidMount = () => {
		this._mounted = true;

		let img = new window.Image();

		img.onload = () => {
			if (this._mounted) {
				this.setState({ loaded: true });
			}
		};

		img.src = this.props.src;
	};

	componentWillUnmount = () => {
		this._mounted = false;
	};

	render = () => {
		const { onLoad } = this.props;

		const { loaded } = this.state;

		if (typeof onLoad === 'function' && (!this._loaded && loaded)) {
			this._loaded = true;

			onLoad();
		}

		return <Image className={classnames({ loaded: loaded })} {...this.props} />;
	};
}
