// npm
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

// cliente
// 6Lf0rrYUAAAAAFfU4WIjSBjT4qhJ4QKQqVGltHyf

// server
// 6Lf0rrYUAAAAAPgukg4UQYQCXVlaxkglglH86vgT

// default
export default () => <ReCAPTCHA sitekey='6Lf0rrYUAAAAAFfU4WIjSBjT4qhJ4QKQqVGltHyf' />;
