// npm
import React from 'react';
import styled from 'styled-components';
import classname from 'classnames';

// components
import Image from '../../components/image';

// style
class Style {
	static Button = styled.span`
		transition: all 0.2s ease-in-out;

		border: 0.125rem solid rgba(245, 245, 245, 1);
		box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
		background: transparent;
		display: inline-flex;
		border-radius: 1rem;
		padding: 2rem 1rem;
		flex-flow: column;
		max-width: 15rem;
		overflow: hidden;
		cursor: pointer;
		margin: 1.5rem;
		width: 100%;

		&.selected {
			box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
			border-color: rgb(0, 204, 153);
		}
	`;

	static Text = styled.div`
		margin-bottom: 2rem;
		text-align: center;
		font-weight: 700;
		display: block;
	`;

	static Flag = styled.div`
		flex: 0 0 auto;

		flex-flow: column;
		display: flex;
		width: 100%;

		& > img {
			flex: 0 0 auto;

			display: block;
			margin: 0 auto;
			height: 2.5rem;
		}
	`;
}

// default
export default ({ Flag = false, text = '', selected = false, onClick = null }) => {
	return (
		<Style.Button className={classname({ selected })} onClick={onClick}>
			<Style.Text children={text} />

			{Flag && (
				<Style.Flag>
					<Image src={Flag} />
				</Style.Flag>
			)}
		</Style.Button>
	);
};
