// npm
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useSelector } from 'react-redux';

// assets
import HeaderImg from '../../assets/img/header.jpg';

// app
import Lang from '../../components/app/language';
import Url from '../../components/app/url';

// components
import Image from '../../components/image';
import Container from '../../components/container';

// styled
export const Header = styled.section`
	background: rgba(29, 29, 29, 1);
	position: relative;
	min-height: 100%;
	overflow: hidden;

	${Container} {
		padding-top: 18rem;
		flex-flow: column;
		display: flex;
		z-index: 2000;
	}
`;

export const BackgroundAnimation = keyframes`
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
`;

export const Background = styled.div`
	animation: ${BackgroundAnimation} 30s linear infinite;
	position: absolute;
	z-index: 1000;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;

	> img {
		position: absolute;
		object-fit: cover;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
	}
`;

export const Title = styled.h1`
	color: rgba(255, 255, 255, 1);
	margin-bottom: 2rem;
	text-align: center;
	font-size: 3rem;
	display: block;
`;

export const Description = styled.h6`
	color: rgba(255, 255, 255, 1);
	margin-bottom: 3rem;
	text-align: center;
	font-size: 1.5rem;
	display: block;
`;

export const Actions = styled.div`
	justify-content: center;
	margin-bottom: 3rem;
	align-items: center;
	flex-flow: row;
	display: flex;

	@media all and (max-width: 425px) {
		flex-flow: column;
	}
`;

export const Action = styled.a`
	flex: 0 0 auto;

	flex-flow: column;
	margin: 0 1rem;
	display: flex;

	& > img {
		height: 3rem;
	}

	@media all and (max-width: 425px) {
		margin: 1rem 0;
	}
`;

export const ImageContainer = styled.div`
	max-width: 30rem;
	margin: 0 auto;
	width: 100%;
`;

export const ImageBox = styled.div`
	padding-bottom: 65%;
	margin-bottom: 3rem;
	position: relative;
	width: 100%;
	height: 0;

	> img {
		object-fit: contain;
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
	}
`;

// default
export default props => {
	const lang = useSelector(s => s.lang);

	return (
		<Header {...props}>
			<Background>
				<Image src={HeaderImg} />
			</Background>

			<Container>
				<Title>
					<Lang id='home.header.title' />
				</Title>

				<Description>
					<Lang id='home.header.description' />
				</Description>

				<Actions>
					<Action target='_blank' href='https://apps.apple.com/br/app/docdo/id1126484326'>
						<Image src={Url(`img/apple-${lang.id}.svg`)} />
					</Action>

					<Action target='_blank' href='https://play.google.com/store/apps/details?id=br.com.infinibrains.docdo'>
						<Image src={Url(`img/google-${lang.id}.png`)} />
					</Action>
				</Actions>

				<ImageContainer>
					<ImageBox>
						<Image src={Url('img/ernesto.png')} />
					</ImageBox>
				</ImageContainer>
			</Container>
		</Header>
	);
};
