// retorna a url base
export default path => {
	let url = false;

	switch (window.location.hostname) {
		case 'localhost': {
			url = `${window.location.origin}`;
			break;
		}

		case 'docdo.com.br':
		case 'www.docdo.com.br': {
			url = `${window.location.origin}/v4`;
			break;
		}

		default: {
			break;
		}
	}

	return url ? `${url}/${path}` : false;
};
